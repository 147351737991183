export default {
  init() {
    const images = [];
    $(".station__body")
      .find("img")
      .each(function () {
        images.push([$(this), $(this).attr("src")]);
      });

    setInterval(function () {
      images.forEach((item) => {
        var oldSrc = item[1];
        var newSrc = oldSrc + "?rand=" + Math.random();
        item[0].attr("src", newSrc);
      });
    }, 10000);
  },
};
