const bodyScrollLock = require("body-scroll-lock");
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

export default {
  init() {
    $(".js-burger").on("click", function (e) {
      e.preventDefault();
      const targetElement = document.querySelector(".js-mobile-menu");
      if ($(this).hasClass("burger--is-active")) {
        $(this).toggleClass("burger--is-active");
        $(targetElement).toggleClass("mobile-menu--is-opened");
        bodyScrollLock.enableBodyScroll(targetElement);
      } else {
        $(this).toggleClass("burger--is-active");
        $(targetElement).toggleClass("mobile-menu--is-opened");
        bodyScrollLock.disableBodyScroll(targetElement);
      }
    });

    $(".header__link").on("click", function () {
      const targetElement = document.querySelector(".js-mobile-menu");
      if ($(targetElement).hasClass("mobile-menu--is-opened")) {
        $(".js-burger").removeClass("burger--is-active");
        $(targetElement).removeClass("mobile-menu--is-opened");
        bodyScrollLock.enableBodyScroll(targetElement);
      }
    });
  },
};
