import "../scss/style.scss";

import Burger from "./components/burger";
import BackToTop from "./components/backToTop";
import SmoothScroll from "./components/smoothScroll";
import ImgReloader from "./components/imgReloader";

$(document).ready(function () {
  // back to top animation
  BackToTop.init();
  // smooth menu scroll
  SmoothScroll.init();
  // burger and mobile menu
  Burger.init();
  // burger and mobile menu
  ImgReloader.init();
});
